import React from "react"
import {graphql } from "gatsby"
import {Container, Wrapper, Article, Info, Title, SubTitle, Para, List, Item, Header, Picture, GotoMenu} from "../styles/homeStyles"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import SideMenu from "../components/sideMenu"
//import Service from "../components/service"
import Newest from '../components/newest';
import {getImage} from "../helpers";

export default ({ data }) => (
  <Layout>
    <SEO 
    title="Home"
    discription="SR Affordable Equipment is a heavy equipment dealer located in the Inland Northwest. We offer a wide variety of equipment services at a great value. We offer quality construction equipment for sale and rent. SR Affordable Equipment also has a full-service shop with factory trained technicians. You can trust our knowledgeable and friendly staff at SR Affordable Equipment to serve your heavy equipment needs. Give us a call anytime!"
    keywords={["Used Heavy Equipment Sales", "Auctions", "SR Affordale Equipment", "Construction Equipment", "Heavy Equipment for Sale", "Attachments", "Excavators", "Skid Steers", "Tractors", process.env.GATSBY_PHONE_NUMBER, process.env.GATSBY_PHONE_NUMBER]}
    />
    <Hero/>
    <Container>
      <Wrapper><SideMenu/>
      <Article>
        <Info>
          <Title>HEAVY EQUIPMENT SALES</Title>
          <SubTitle>Used Heavy Equipment Sales in Spokane, WA</SubTitle>
          <Para>SR Affordable Equipment, a premier used heavy equipment dealer in Spokane, WA, specializes in providing high-quality excavators, skid steers, and tractors to cater to the diverse needs of its clientele. The company is dedicated to customer satisfaction and ensures that each piece of machinery undergoes thorough inspection and maintenance to deliver exceptional performance and reliability. SREquipment takes pride in offering competitive pricing and comprehensive after-sales support, making it an ideal destination for contractors, farmers, and other industry professionals seeking dependable used heavy equipment without the added burden of financing options. Give us a call anytime!</Para>
        </Info>
        <Info>
          <Title>BROWSE OUR USED EQUIPMENT INVENTORY</Title>
          <List>
            {data.allTaxonomyTermCategory.edges.map(({ node, i }) => {
              let image = getImage(node);
              return (
                <Item key={node.name}>
                  <GotoMenu to={`${node.path.alias}/`}>
                    <Header>{node.name}</Header>
                    <Picture alt={image.alt} title={image.title} src={image.url} />
                  </GotoMenu>
                </Item>
              )
            })}
          </List>
        </Info>
      </Article></Wrapper>
    </Container>
    {/*<Service/>*/}
    <Newest/>
  </Layout>
)

export const query = graphql`
    query {
        allTaxonomyTermCategory(sort: {name: ASC}) {
        edges {
            node {
            name
            path {
              alias
            }
            relationships {
                field_image {
                field_media_image {
                  alt
                  title
                }
                relationships {
                    field_media_image {
                      url
                    }
                  }
                }
              }
            }
          }
        }
    }
`
